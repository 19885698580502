<template>
  <div v-show="!isLoading" class="flex gap-24 mt-40">
    <div class="flex-1 flex flex-col gap-24">
      <s-text as="h3" role="title6" class="text-on-surface-elevation-1 !font-bold">
        {{ productName }}
      </s-text>
      <st-box>
        <div class="flex flex-col gap-32">
          <div>
            <div
              v-if="!paidYn"
              class="rounded-2xl bg-[#FFE7E7] mb-32 py-8 px-16 flex gap-4 items-start"
            >
              <s-icon icon="ic-v2-state-info-circle-line" size="xl" class="mt-[.3rem]" />
              <p class="text-sm leading-md font-medium text-on-surface-elevation-2">
                {{
                  $t(
                    'studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_free_guide'
                  )
                }}
              </p>
            </div>
            <st-form-title
              :formTitle="$t('studio.group.collection.discount_registration_discount_name')"
              required
            />
            <input-text
              name="discountName"
              :placeholder="
                $t('studio.group.collection.discount_registration_discount_name_place_holder')
              "
              :rules="{
                required: {
                  value: true,
                  message: $t('studio.group.collection.discount_registration_discount_name_msg1')
                },
                max_length: 30
              }"
              :count="0"
              maxLength="30"
              countable
              :disabled="!isMenuWritable"
            />
          </div>
          <div v-show="!isDisabled">
            <st-form-title
              :formTitle="$t('studio.group.collection.discount_registration_start_date')"
              required
            />
            <p class="text-sm font-regular leading-md text-on-surface-elevation-4">
              <safe-html
                :html="$t('studio.event_period_settings.sel_discount_immediate_apply.possible_delay_msg')"
              />
            </p>
            <div>
              <radio-group
                name="startDateType"
                :options="startDateOptions"
                class="flex flex-col gap-8 pt-8"
                :optionProps="{
                  size: 'sm',
                  align: 'middle'
                }"
                :disabled="!isMenuWritable"
              />
            </div>
            <div v-if="values.startDateType === START_DATE_TYPE.SELECT" class="w-full mt-8">
              <datetime-picker
                name="startDate"
                :disabledDates="disabledStartDates"
                :isDisabled="!isMenuWritable || isDisabled"
                showUtc
                @update:modelValue="changeStartDate"
              />
            </div>
          </div>
          <div>
            <st-form-title
              :formTitle="$t('studio.group.collection.discount_registration_end_date')"
              required
            />
            <div class="w-full mt-8">
              <datetime-picker
                showUtc
                name="endDate"
                :disabledDates="disabledEndDates"
                :isDisabled="!isMenuWritable || isDisabled"
                @update:modelValue="changeEndDate"
              />
            </div>
            <p
              v-if="periodError || periodServerError"
              class="flex items-start gap-[.2rem] mt-[.4rem] text-error text-xs leading-xs font-regular"
            >
              <s-icon
                icon="ic-v2-state-warning-circle-fill"
                size="xl"
                class="flex h-16 mt-[.1rem]"
              />
              {{ periodError || periodServerError }}
            </p>
          </div>
          <div>
            <st-form-title
              :formTitle="$t('studio.group.collection.discount_registration_rate')"
              required
            />
            <div class="flex gap-8 items-start">
              <span>
                <input-text
                  size="sm"
                  variant="outline"
                  placeholder="0"
                  containerClass="w-160"
                  inputClass="text-on-surface-elevation-1 text-right tracking-[0]"
                  :rules="{
                    required: $t(
                      'studio.prj_prod.this_prod.prod_mgmt_discount_register_discount_rate_req_val_msg1'
                    )
                  }"
                  name="discountRate"
                  :min="1"
                  :max="100"
                  :type="InputTextTypes.Number"
                  :disabled="!isMenuWritable || isDisabled"
                  @update:modelValue="changeDiscountRate"
                >
                  <template #right>
                    <s-text
                      as="span"
                      role="text3"
                      class="font-medium text-on-surface-elevation-3 !leading-[3.2rem] absolute right-[-3.2rem]"
                    >
                      %
                    </s-text>
                  </template>
                </input-text>
              </span>
            </div>
          </div>
          <div v-show="!isDisabled">
            <st-form-title
              :formTitle="
                $t(
                  'studio.prj_prod.this_prod.release_details_sales_setting_price_setting_introductory_discount_discounted_price_title'
                )
              "
            />
            <s-text as="p" role="cap1" class="text-placeholder">
              <safe-html
                tag="span"
                :html="$t('studio.group.collection.register_price_preview_guide')"
              />
            </s-text>
            <div v-if="paidYn" class="mt-16">
              <div class="flex justify-end gap-8">
                <div class="relative w-180 dropdown-bg">
                  <dropdown
                    v-model="currency"
                    containerClass="w-full border-border"
                    :dropdownProps="{
                      class: 'w-full'
                    }"
                    name="currencyStandard"
                    :options="currencyStandardOptions"
                  />
                </div>
                <div class="relative w-180 dropdown-bg">
                  <dropdown
                    v-model="priceType"
                    name="priceStandard"
                    containerClass="w-full border-border"
                    :dropdownProps="{
                      class: 'w-full'
                    }"
                    :options="priceStandardOptions"
                  />
                </div>
              </div>
              <s-text as="p" role="text4" class="flex justify-between mt-20">
                <span class="!font-regular">{{
                  $t('studio.group.collection.register_price_preview_sub_title1')
                }}</span>
                <span>{{
                  $t('studio.group.collection.register_price_preview_sub_title2', {
                    prodNum: productList.length
                  })
                }}</span>
              </s-text>
              <div class="rounded-xl bg-[#F7FAFD] p-20 mt-8">
                <template v-if="productList && productList.length > 0">
                  <ul class="flex flex-col gap-8 pb-16">
                    <li
                      v-for="product in productList"
                      :key="product.id"
                      class="flex justify-between text-on-surface-elevation-2"
                    >
                      <s-text as="span" role="text3" class="font-medium">{{ product.name }}</s-text>
                      <s-text
                        v-if="!product.discountRate"
                        as="span"
                        role="text3"
                        class="text-on-surface-elevation-3"
                      >
                        {{ currencySymbol
                        }}{{ formatPrice(getExchangedPrice(product), currencyStandardDisplay) }}
                      </s-text>
                      <s-text
                        v-else
                        as="span"
                        role="text3"
                        class="flex gap-8 text-on-surface-elevation-3"
                      >
                        <span
                          aria-label="할인율 퍼센트"
                          class="text-tint-red-a400 font-bold"
                        >{{ product.discountRate }}%</span>
                        <span
                          aria-label="이전 가격"
                          class="line-through"
                        >{{ currencySymbol
                        }}{{
                          formatPrice(getExchangedPrice(product, true), currencyStandardDisplay)
                        }}</span>
                        <span aria-label="새로운 가격">{{ currencySymbol
                        }}{{
                          formatPrice(
                            getExchangedDiscountPrice(getExchangedPrice(product, true), product),
                            currencyStandardDisplay
                          )
                        }}</span>
                      </s-text>
                    </li>
                  </ul>

                  <div class="border-t border-solid border-border pt-16">
                    <s-text as="p" role="text3" class="flex justify-between">
                      <span class="text-on-surface-elevation-2 font-bold">{{
                        $t('studio.group.collection.register_price_preview_pay_info1')
                      }}</span>
                      <span class="text-on-surface-elevation-3">{{ currencySymbol
                      }}{{ formatPrice(getTotalBeforeDiscount(), currencyStandardDisplay) }}</span>
                    </s-text>
                    <div class="flex flex-col py-16 pl-16 gap-8">
                      <s-text as="p" role="text4" class="flex justify-between">
                        <span class="text-on-surface-elevation-3">{{
                          $t('studio.group.collection.register_price_preview_pay_info2')
                        }}</span>
                        <span class="text-tint-red-a400 font-bold">-{{ discountRate > 0 ? discountRate : 0 }}%</span>
                      </s-text>
                      <s-text as="p" role="text4" class="flex justify-between">
                        <span class="text-on-surface-elevation-3">{{
                          $t('studio.group.collection.register_price_preview_pay_info3')
                        }}</span>
                        <span class="text-tint-red-a400 font-bold">-{{ currencySymbol
                        }}{{
                          formatPrice(getTotalDiscount(), currencyStandardDisplay)
                        }}</span>
                      </s-text>
                    </div>
                  </div>
                  <div
                    class="flex justify-between text-on-surface-elevation-2 border-t border-solid border-border pt-16"
                  >
                    <s-text as="span" role="text3" class="font-bold">
                      {{ $t('studio.group.collection.register_price_preview_pay_info4') }}
                    </s-text>
                    <s-text as="span" role="title5" class="font-bold">
                      {{ currencySymbol
                      }}
                      {{ formatPrice(getTotalAfterDiscount(), currencyStandardDisplay) }}
                    </s-text>
                  </div>
                </template>
                <p
                  v-else
                  class="h-120 flex items-center justify-center text-sm text-on-surface-elevation-4"
                >
                  {{ $t('studio.group.collection.register_product_config_list_msg4') }}
                </p>
              </div>
            </div>
            <div v-else class="mt-8 text-md font-bold leading-lg text-on-surface-elevation-2">
              {{
                $t(
                  'studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_free_msg'
                )
              }}
            </div>
          </div>
          <div>
            <st-form-title
              :formTitle="$t('studio.group.collection.discount_registration_limited_sale')"
              required
            />
            <radio-group
              name="isLimitedSale"
              :optionProps="{
                size: 'sm',
                align: 'middle'
              }"
              :options="limitQuantityDiscountSaleOptions"
              class="flex gap-24 pt-8"
              :disabled="!isMenuWritable || isDisabled"
            />
          </div>
          <div v-if="values.isLimitedSale === COMMON_RADIO_TYPE.USED">
            <st-form-title
              :formTitle="$t('studio.group.collection.discount_registration_limited_quantity')"
              required
            />
            <div class="flex items-start gap-8">
              <span class="w-200">
                <input-text
                  size="sm"
                  placeholder="0"
                  inputClass="text-on-surface-elevation-1 text-right tracking-[0]"
                  name="limitedQuantity"
                  :rules="{
                    required: $t(
                      'studio.prj_prod.this_prod.prod_mgmt_discount_register_discount_rate_req_val_msg1'
                    ),
                    regex: {
                      regex: ONLY_NUMBER_WITH_COMMA_REGEX,
                      message: $t('studio.common.def_key.number_only_y')
                    },
                    max_length: {
                      length: 9,
                      message: $t(
                        'studio.group.collection.discount_registration_limited_quantity_msg1'
                      )
                    },
                    gt: {
                      value: 0,
                      message: $t(
                        'studio.group.collection.discount_registration_limited_quantity_msg1'
                      )
                    }
                  }"
                  :disabled="!isMenuWritable || isDisabled"
                  maxLength="9"
                  :countable="false"
                  :allowInputMaxLength="false"
                  :pattern="ONLY_NUMBER_WITH_COMMA_REGEX"
                />
              </span>
              <s-text
                as="span"
                role="text3"
                class="font-medium text-on-surface-elevation-3 !leading-[3.2rem]"
              >
                {{ $t('studio.group.collection.discount_registration_limited_quantity_text') }}
              </s-text>
            </div>
          </div>
        </div>
      </st-box>
      <div class="mt-16 flex justify-center">
        <s-button variant="primary" size="lg" class="!min-w-160" @click="handleSubmitDiscount">
          {{ $t('studio.common.live_apply_btn') }}
        </s-button>
      </div>
    </div>
    <right-wing>
      <right-wing-item
        v-for="(guide, idx) in showGuides"
        :key="guide.title + idx"
        :rightWingTitle="guide.title"
        :numberValue="`0${idx + 1}`"
        :class="{
          'mt-24': idx > 0
        }"
      >
        <safe-html :html="guide.content" />
      </right-wing-item>
    </right-wing>
  </div>
</template>
<script setup lang="ts">
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm } from 'vee-validate';
import { computed, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { fetchCollectionDetailsApi } from '@/apis/collection.api';
import {
  createProductDiscountApi,
  fetchProductDiscountApi,
  fetchProductDiscountsApi,
  updateProductDiscountApi
} from '@/apis/product-discount.api';
import { fetchProductsSellingPriceApi } from '@/apis/product-selling-price.api';
import LiveConfirmDialog from '@/components/app/dialog/live-confirm-dialog.vue';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import DatetimePicker from '@/components/validation/datetime-picker.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import InputText from '@/components/validation/input-text.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showDialog } from '@/composables/useDialog';
import { COMMON_RADIO_TYPE, MODE, START_DATE_TYPE } from '@/constants/common.const';
import { currencyCodes, NON_DIGIT_CURRENCY_CODES } from '@/constants/currency-codes.const';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import { DISCOUNT_STATUS } from '@/constants/products-discount.const';
import { ONLY_NUMBER_WITH_COMMA_REGEX } from '@/constants/regex.const';
import { MIN_VALUE_PRICE_INPUT, MIN_VALUE_PRICE_INPUT_KRW } from '@/constants/selling-price.const';
import { COLLECTION_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { CurrencyCode } from '@/enums/currency-code.enum';
import { InputTextTypes, RuleNames } from '@/enums/validation.enum';
import { useAppStore } from '@/stores/app.store';
import { useCurrencyStore } from '@/stores/currency.store';
import type {
  CollectionChildDetails,
  CollectionInfos,
  CollectionProductItem
} from '@/types/collection/collection.type';
import type { CollectionDetailResponse } from '@/types/collection/collection-response.type';
import type { ErrorResponse } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type { CurrencyInfoModel } from '@/types/currency/currency-common.type';
import type { DiscountCurrencies } from '@/types/product-discount/product-discount-model.type';
import type { CreateUpdateProductDiscountRequest } from '@/types/product-discount/product-discount-request.type';
import type {
  ProductDiscountDetailResponse,
  ProductDiscountResponse
} from '@/types/product-discount/product-discount-response.type';
import type {
  PriceAppliedType,
  PriceListType
} from '@/types/product-selling-price/product-selling-price.type';
import { throwContentError } from '@/utils/api-error.util';
import { redirectTo } from '@/utils/common.util';
import {
  convertCurrencyToSymbol,
  formatNumberMultipleWithCommas,
  formatPrice,
  getRoundedDownMoney,
  getVndDigitsPrice,
  truncateToDecimals
} from '@/utils/currency.util';
import {
  displayPriceAfterDiscount,
  getCollectionDiscountCurrencies,
  paymentPriceAfterDiscount
} from '@/utils/price.util';
import { generateErrorMsg } from '@/utils/validation.util';

const PRICE_STANDARD_VALUES = {
  STORE: 'store',
  PAYMENT: 'payment'
};

interface DiscountForm {
  discountName: string;
  startDate: Date;
  startDateType: string;
  endDate: Date;
  endHour: number;
  endMinute: number;
  discountRate: number | null;
  isLimitedSale: string;
  limitedQuantity: string | null;
  currencyStandard: string;
  priceStandard: string;
}

const { t, locale } = useI18n();
const route = useRoute();
const collectionId = route.params.collectionId;
const isLoading = ref<boolean>(true);
const collectionInfo = ref<CollectionDetailResponse>();
const periodServerError = ref<string>('');
const priceList = ref<PriceAppliedType[]>([]);
const discountList = ref<ProductDiscountResponse[]>([]);
const discountDetails = ref<ProductDiscountDetailResponse>();
const paidYn = ref(false);
const currency = ref<CurrencyCode>(CurrencyCode.Usd);
const priceType = ref(PRICE_STANDARD_VALUES.STORE);
const exchangeRates = ref<CurrencyInfoModel[]>([]);
const isDisabled = ref<boolean>(false);
const underAllowPrice = ref(false);

const { checkMenuWritable } = useApp();

const { isMenuWritable } = storeToRefs(useAppStore());

const currencyStore = useCurrencyStore();
const { fetchCurrencyInfos } = currencyStore;

const currencyStandardOptions = ref<FormOption[]>([
  {
    label: `${t('studio.national_code.us')} USD`,
    value: 'USD'
  },
  {
    label: `${t('studio.national_code.kr')} KRW`,
    value: 'KRW'
  },
  {
    label: `${t('studio.national_code.cn')} CNY`,
    value: 'CNY'
  },
  {
    label: `${t(
      'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_eur_msg'
    )} EUR`,
    value: 'EUR'
  },
  {
    label: `${t('studio.national_code.gb')} GBP`,
    value: 'GBP'
  },
  {
    label: `${t('studio.national_code.hk')} HKD`,
    value: 'HKD'
  },
  {
    label: `${t('studio.national_code.id')} IDR`,
    value: 'IDR'
  },
  {
    label: `${t('studio.national_code.jp')} JPY`,
    value: 'JPY'
  },
  {
    label: `${t('studio.national_code.my')} MYR`,
    value: 'MYR'
  },
  {
    label: `${t('studio.national_code.ph')} PHP`,
    value: 'PHP'
  },
  {
    label: `${t('studio.national_code.sg')} SGD`,
    value: 'SGD'
  },
  {
    label: `${t('studio.national_code.th')} THB`,
    value: 'THB'
  },
  {
    label: `${t('studio.national_code.vn')} VND`,
    value: 'VND'
  }
]);

const showGuides = [
  {
    title: t('studio.prj_prod.this_prod.prod_mgmt_discount_register_guide1'),
    content: t('studio.prj_prod.this_prod.prod_mgmt_discount_register_guide1_1')
  },
  {
    title: t('studio.prj_prod.this_prod.prod_mgmt_discount_register_guide2'),
    content: t('studio.prj_prod.this_prod.prod_mgmt_discount_register_guide2_1')
  },
  {
    title: t('studio.prj_prod.this_prod.prod_mgmt_discount_register_guide3'),
    content: t('studio.prj_prod.this_prod.prod_mgmt_discount_register_guide3_1')
  }
];

const priceStandardOptions = ref<FormOption[]>([
  {
    label: t('studio.group.collection.register_price_preview_select1'),
    value: 'store'
  },
  {
    label: t('studio.group.collection.register_price_preview_select2'),
    value: 'payment'
  }
]);

const startDateOptions = [
  {
    label: t('studio.group.collection.discount_registration_start_date_option1'),
    value: START_DATE_TYPE.NOW
  },
  {
    label: t('studio.group.collection.discount_registration_start_date_option2'),
    value: START_DATE_TYPE.SELECT
  }
];

const limitQuantityDiscountSaleOptions = [
  {
    label: t('studio.group.collection.discount_registration_limited_sale_y'),
    value: COMMON_RADIO_TYPE.USED
  },
  {
    label: t('studio.group.collection.discount_registration_limited_sale_n'),
    value: COMMON_RADIO_TYPE.UNUSED
  }
];

const today = DateTime.local().startOf('day').toJSDate();

const disabledStartDates = {
  to: today
};

const disabledEndDates = {
  to: today
};

const defaultStartDate = DateTime.local().plus({ minutes: 1 });
const defaultEndDate = DateTime.local().plus({ days: 7, minutes: 1 });

const { handleSubmit, values, setValues, setErrors } = useForm<DiscountForm>({
  initialValues: {
    discountName: '',
    startDate: defaultStartDate.toJSDate(),
    startDateType: START_DATE_TYPE.NOW,
    endDate: defaultEndDate.toJSDate(),
    discountRate: null,
    isLimitedSale: COMMON_RADIO_TYPE.UNUSED,
    limitedQuantity: null,
    currencyStandard: 'USD',
    priceStandard: 'store'
  }
});

const discountRate = useFieldValue<number>('discountRate');
const currencyStandard = useFieldValue<string>('currencyStandard');

const currencySymbol = computed(() => {
  if (priceType.value === PRICE_STANDARD_VALUES.PAYMENT && currency.value !== CurrencyCode.Krw) {
    return convertCurrencyToSymbol(CurrencyCode.Usd);
  }
  return convertCurrencyToSymbol(currencyStandard.value as CurrencyCode);
});

const currencyStandardDisplay = computed(() => {
  if (priceType.value === PRICE_STANDARD_VALUES.PAYMENT && currency.value !== CurrencyCode.Krw) {
    return CurrencyCode.Usd;
  }
  return currencyStandard.value;
});

const productName = computed(
  () =>
    collectionInfo.value?.productInfo.find(
      (c: CollectionInfos) => c.languageCd === locale.value
    )?.productName || ''
);

const periodError = computed(() => {
  const startDate = DateTime.fromJSDate(values.startDate).set({ second: 0, millisecond: 0 });
  const endDate = DateTime.fromJSDate(values.endDate).set({ second: 0, millisecond: 0 });
  const currentDate = DateTime.local();

  if (values.startDateType === START_DATE_TYPE.SELECT) {
    if (startDate <= currentDate) {
      return t('studio.group.collection.discount_registration_end_date_msg2');
    }
  }

  if (startDate >= endDate) {
    return t('studio.prj_prod.this_prod.prod_mgmt_discount_register_end_date_invalid_val_msg3');
  }

  return '';
});

const getTotalBeforeDiscount = () => {
  const price = collectionPriceList.value.find((item: PriceListType) => item.displayCurrencyCode === currencyStandardDisplay.value);
  if (price) {
    if (priceType.value === PRICE_STANDARD_VALUES.STORE) {
      return price.originalDisplayPrice ?? 0;
    }
    return price.originalSalesPrice ?? 0;
  }

  return 0;
};

const getTotalDiscount = () => {
  const price = collectionPriceList.value.find((item: PriceListType) => item.displayCurrencyCode === currencyStandardDisplay.value);
  if (price) {
    if (priceType.value === PRICE_STANDARD_VALUES.STORE) {
      return (price.originalDisplayPrice ?? 0) - price.displayPrice;
    } else {
      return (price.originalSalesPrice ?? 0) - price.salesPrice;
    }
  }

  return 0;
};

const getTotalAfterDiscount = () => {
  const price = collectionPriceList.value.find((item: PriceListType) => item.displayCurrencyCode === currencyStandardDisplay.value);
  if (price) {
    if (priceType.value === PRICE_STANDARD_VALUES.STORE) {
      return price.displayPrice;
    } else {
      return price.salesPrice;
    }
  }

  return 0;
};

const changeStartDate = () => {
  periodServerError.value = '';
  const startDate = DateTime.fromJSDate(values.startDate).set({ second: 0, millisecond: 0 });
  const endDate = DateTime.fromJSDate(values.endDate).set({ second: 0, millisecond: 0 });
  if (startDate >= endDate) {
    setValues({
      endDate: startDate.plus({ days: 1 }).toJSDate()
    });
  }
};

const changeEndDate = () => {
  periodServerError.value = '';
};

const checkUnderAllowPrice = () => {
  const discountCurrencies = getCollectionDiscountCurrencies(collectionPriceList.value);
  underAllowPrice.value = discountCurrencies.some(
    (currency: DiscountCurrencies) => {
      const minValue = currency.salesCurrencyCode === CurrencyCode.Krw ? MIN_VALUE_PRICE_INPUT_KRW : MIN_VALUE_PRICE_INPUT;
      if (currency.salesPrice < minValue && currency.salesPrice !== 0) {
        return true;
      }

      return false;
    }
  );

  if (underAllowPrice.value) {
    setTimeout(() => {
      const errorMessage = generateErrorMsg(
        'studio.discount.discount_rate_min_payment_range_val_msg',
        RuleNames.CHECK_DUPLICATE
      );
      setErrors({ discountRate: errorMessage });
    });
  }
};

const changeDiscountRate = () => {
  checkUnderAllowPrice();
};

const handleSubmitDiscount = async () => {
  if (
    !(await checkMenuWritable({
      redirectUrl: `${COLLECTION_URL}/${collectionId}/discount`
    }))
  ) {
    return;
  }

  onSubmit();
};

const onSubmit = handleSubmit(async (values: DiscountForm) => {
  if (periodError.value || periodServerError.value) {
    return;
  }

  checkUnderAllowPrice();
  if (underAllowPrice.value) {
    return;
  }

  const result = await showDialog({
    component: shallowRef(LiveConfirmDialog),
    severity: 'info'
  });

  if (result) {
    const { collectionId } = route.params;
    const {
      discountName,
      discountRate,
      isLimitedSale,
      limitedQuantity,
      startDateType,
      startDate,
      endDate,
      endHour,
      endMinute
    } = values;

    const params: CreateUpdateProductDiscountRequest = {
      discountName,
      discountRate: Number(discountRate),
      salesLimitYn: isLimitedSale === COMMON_RADIO_TYPE.USED ? Confirmation.YES : Confirmation.NO,
      salesLimitCount:
        isLimitedSale === COMMON_RADIO_TYPE.USED
          ? limitedQuantity
            ? Number(limitedQuantity.replace(/,/g, ''))
            : 0
          : undefined,
      startAt:
        startDateType === START_DATE_TYPE.NOW
          ? DateTime.local().toMillis()
          : DateTime.fromJSDate(startDate).toMillis(),
      endAt: DateTime.fromJSDate(endDate).set({ hour: endHour, minute: endMinute }).toMillis(),
      discountCurrencies: getCollectionDiscountCurrencies(collectionPriceList.value)
    };

    try {
      const response = discountDetails.value?.discountNo
        ? await updateProductDiscountApi(
          Number(collectionId),
          discountDetails.value?.discountNo,
          params
        )
        : await createProductDiscountApi(Number(collectionId), params);

      if (response) {
        await showAlert({
          content: t('studio.common.popup_case_d_complete_save'),
          confirmLabel: t('studio.common.popup_case_cf_btn')
        });
        redirectTo(`${COLLECTION_URL}/${collectionId}/discount`);
      } else {
        showAlert({
          content: t(COMMON_ERROR_MESSAGE_KEY),
          severity: 'info',
          confirmLabel: t('studio.common.popup_case_cf_btn'),
          confirmClasses: '!w-full !max-w-full'
        });
      }
    } catch (err) {
      const error = err as ErrorResponse;
      const errorCode = error.statusCode ?? 0;
      if (errorCode === STATUS_CODE.PRODUCT_DISCOUNT_PERIOD_DUPLICATED) {
        periodServerError.value = t(
          'studio.prj_prod.this_prod.prod_mgmt_discount_register_dup_period_val_msg1'
        );
      } else if (errorCode === STATUS_CODE.PRODUCT_DISCOUNT_PERIOD_IS_PAST) {
        periodServerError.value = t(
          'studio.prj_prod.this_prod.prod_mgmt_discount_register_reset_time_val_msg2'
        );
      } else {
        await throwContentError(COMMON_ERROR_MESSAGE_KEY);
      }
    }
  }
});

const productList = computed<CollectionProductItem[]>(
  () =>
    collectionInfo.value?.childProducts.map((product: CollectionChildDetails) => {
      const price =
        priceList.value.find((price: PriceAppliedType) => price.productNo === product.productNo)
          ?.defaultPrice || 0;
      const discount =
        discountList.value.find(
          (discount: ProductDiscountResponse) => discount.productNo === product.productNo
        )?.discountRate || 0;
      return {
        id: product.productNo,
        name: product.productName,
        price,
        priceList:
          priceList.value.find((price: PriceAppliedType) => price.productNo === product.productNo)
            ?.priceList ?? [],
        discountRate: discount,
        discountPrice: price - price * (discount / 100)
      };
    }) || []
);

const initCurrencyList = ref<PriceListType[]>(
  currencyCodes.map((code: CurrencyCode) => {
    return {
      displayCurrencyCode: code,
      salesCurrencyCode: code === CurrencyCode.Krw ? CurrencyCode.Krw : CurrencyCode.Usd,
      salesPrice: 0,
      displayPrice: 0
    };
  })
);

const getExchangeInfo = (currencyCode: string) => {
  return exchangeRates.value?.find((rate: CurrencyInfoModel) => rate.currencyCode === currencyCode);
};

const collectionPriceList = computed(() => {
  return initCurrencyList.value.map((curr: PriceListType) => {
    let salesPrice = 0;
    let displayPrice = 0;

    let originalSalesPrice = 0;
    let originalDisplayPrice = 0;
    productList.value.forEach((product: CollectionProductItem) => {
      const foundCurrency = product.priceList?.find(
        (currency: PriceListType) => currency.displayCurrencyCode === curr.displayCurrencyCode
      );
      if (foundCurrency) {
        salesPrice += paymentPriceAfterDiscount(
          paymentPriceAfterDiscount(
            foundCurrency.salesPrice,
            product.discountRate ?? 0,
            curr.salesCurrencyCode ?? CurrencyCode.Usd
          ),
          values.discountRate ?? 0,
          curr.salesCurrencyCode ?? CurrencyCode.Usd
        );
        displayPrice += displayPriceAfterDiscount(
          displayPriceAfterDiscount(
            foundCurrency.salesPrice,
            product.discountRate ?? 0,
            curr.displayCurrencyCode,
            getExchangeInfo(curr.displayCurrencyCode)?.exchangeRate
          ),
          values.discountRate ?? 0,
          curr.displayCurrencyCode,
          1
        );

        originalSalesPrice += paymentPriceAfterDiscount(
          foundCurrency.salesPrice,
          product.discountRate ?? 0,
          curr.salesCurrencyCode ?? CurrencyCode.Usd
        );
        originalDisplayPrice += displayPriceAfterDiscount(
          foundCurrency.salesPrice,
          product.discountRate ?? 0,
          curr.displayCurrencyCode,
          getExchangeInfo(curr.displayCurrencyCode)?.exchangeRate
        );
      }
    });

    curr.originalSalesPrice = Number(originalSalesPrice.toFixed(2));
    curr.originalDisplayPrice = displayPriceAfterDiscount(
      Number(originalDisplayPrice.toFixed(2)),
      0,
      curr.displayCurrencyCode as CurrencyCode,
      1
    );

    curr.salesPrice = Number(salesPrice.toFixed(2));
    curr.displayPrice = displayPriceAfterDiscount(
      Number(displayPrice.toFixed(2)),
      0,
      curr.displayCurrencyCode as CurrencyCode,
      1
    );

    return curr;
  });
});
const fetchPriceList = async (childProducts: number[]) => {
  const result = await fetchProductsSellingPriceApi(childProducts);

  if (result) {
    priceList.value = result.contents;
    if (result.contents.some((item: { paidYn: string }) => item.paidYn === Confirmation.YES)) {
      paidYn.value = true;
    }
  }
};

const fetchDiscountList = async (childProducts: number[]) => {
  const result = await fetchProductDiscountsApi(childProducts);

  if (result) {
    discountList.value = result.contents;
  }
};

const fetchDiscountDetails = async () => {
  const result = await fetchProductDiscountApi(Number(collectionId), Number(route.params.mode));

  if (result) {
    discountDetails.value = result;
    if (result.withReleaseYn === Confirmation.YES) {
      isDisabled.value = true;
    }
    const { discountName, discountRate, salesLimitYn, salesLimitCount, startAt, endAt } = result;

    setValues({
      discountName,
      startDate: startAt ? new Date(startAt) : defaultStartDate.toJSDate(),
      startDateType: startAt ? START_DATE_TYPE.SELECT : START_DATE_TYPE.NOW,
      endDate: endAt ? new Date(endAt) : defaultEndDate.toJSDate(),
      discountRate,
      isLimitedSale:
        salesLimitYn === Confirmation.YES ? COMMON_RADIO_TYPE.USED : COMMON_RADIO_TYPE.UNUSED,
      limitedQuantity: salesLimitCount ? formatNumberMultipleWithCommas(salesLimitCount) : null,
      currencyStandard: 'USD',
      priceStandard: 'store'
    });
  }
};

const getExchangedPrice = (product: CollectionProductItem, nonDiscount?: boolean, useExchangeRate?: boolean) => {
  const discountRate = nonDiscount ? 0 : product.discountRate || 0;

  if (priceType.value === PRICE_STANDARD_VALUES.PAYMENT) {
    return (
      (product.priceList?.find(
        (price: PriceListType) => price.displayCurrencyCode === currency.value
      )?.salesPrice ?? 0) *
      (1 - discountRate / 100)
    );
  }

  if (useExchangeRate) {
    let exchangeRate = getExchangeInfo(currency.value)?.exchangeRate ?? 1;
    if (currency.value === CurrencyCode.Krw) {
      exchangeRate = 1;
    }

    return (
      (product.priceList?.find((price: PriceListType) => price.displayCurrencyCode === currency.value)
        ?.salesPrice ?? 0) * exchangeRate *
      (1 - discountRate / 100)
    );
  } else {
    return (
      (product.priceList?.find((price: PriceListType) => price.displayCurrencyCode === currency.value)
        ?.displayPrice ?? 0) *
      (1 - discountRate / 100)
    );
  }
};

const getExchangedDiscountPrice = (originalPrice: number, product: CollectionProductItem) => {
  if (
    priceType.value === PRICE_STANDARD_VALUES.STORE ||
    currencyStandard.value === CurrencyCode.Krw
  ) {
    if (NON_DIGIT_CURRENCY_CODES.includes(currencyStandard.value as CurrencyCode)) {
      if (currencyStandard.value === CurrencyCode.Krw) {
        return getRoundedDownMoney(
          originalPrice - (originalPrice * (product.discountRate ?? 0)) / 100
        );
      }
      if (currencyStandard.value === CurrencyCode.Vnd) {
        return getVndDigitsPrice(
          originalPrice - (originalPrice * (product.discountRate ?? 0)) / 100
        );
      }
      return Math.floor(originalPrice - (originalPrice * (product.discountRate ?? 0)) / 100);
    }
  }
  return truncateToDecimals(originalPrice - (originalPrice * (product.discountRate ?? 0)) / 100, 2);
};

const init = async () => {
  const result = await fetchCollectionDetailsApi(Number(collectionId));
  if (!result) {
    return;
  }
  collectionInfo.value = result;

  const childProductNos = collectionInfo.value.childProducts.map(
    (item: CollectionChildDetails) => item.productNo
  );
  fetchPriceList(childProductNos);
  fetchDiscountList(childProductNos);

  if (route.params.mode !== MODE.CREATE) {
    await fetchDiscountDetails();
    if (discountDetails.value?.discountStatus !== DISCOUNT_STATUS.SCHEDULED) {
      await redirectTo(`${COLLECTION_URL}/${collectionId}/discount`);
    }
  }

  exchangeRates.value = await fetchCurrencyInfos(currencyCodes);

  isLoading.value = false;
};

init();
</script>
