<template>
  <st-title
    :navItems="[
      {
        name: $t('studio.group.collection.title'),
        url: 'collections'
      },
      {
        name: $t('studio.group.collection.discount_title'),
        url: `collections/${$route.params.collectionId}/discount`
      },
      {
        name: $t('studio.group.collection.discount_registration_title')
      }
    ]"
    :title="$t('studio.group.collection.discount_registration_title')"
  />
  <view-discount-details v-if="isViewMode" />
  <discount-details-form v-else />
</template>
<script setup lang="ts">
import { useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import DiscountDetailsForm from '@/components/collection/discount-details-form.vue';
import ViewDiscountDetails from '@/components/collection/view-discount-details.vue';
import stTitle from '@/components/common/st-title.vue';
import { MODE } from '@/constants/common.const';

definePageMeta({ middleware: 'check-menu-writable-middleware', editModeAccessible: true });

const route = useRoute();

const isViewMode = route.params.mode !== MODE.CREATE && route.query.mode !== MODE.EDIT;
</script>
