<template>
  <div v-show="!isLoading" class="mt-40">
    <div class="flex-1 flex flex-col gap-24">
      <s-text as="h3" role="title6" class="text-on-surface-elevation-1 !font-bold">
        {{ productName || '' }}
      </s-text>
      <st-box>
        <dl class="flex flex-col gap-20">
          <div class="flex flex-col gap-4">
            <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
              {{ $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_discount_name_title') }}
            </dt>
            <dd>
              <span class="text-xl font-bold leading-xl text-on-surface-elevation-2">{{
                discountDetails?.discountName
              }}</span>
            </dd>
          </div>
          <div class="flex flex-col gap-4">
            <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
              {{ $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_start_date_title') }}
            </dt>
            <dd class="flex gap-4 items-center">
              <span class="text-xl font-bold leading-xl text-on-surface-elevation-2">{{
                formatDateTime(discountDetails?.startAt, DATE_TIME_FORMAT_WITH_DOT)
              }}</span>
              <span class="text-sm font-medium leading-md text-on-surface-elevation-4">({{ utcTimezone }})</span>
            </dd>
          </div>
          <div class="flex flex-col gap-4">
            <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
              {{ $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_end_date_title') }}
            </dt>
            <dd class="flex gap-4 items-center">
              <span class="text-xl font-bold leading-xl text-on-surface-elevation-2">{{
                formatDateTime(discountDetails?.endAt, DATE_TIME_FORMAT_WITH_DOT)
              }}</span>
              <span class="text-sm font-medium leading-md text-on-surface-elevation-4">({{ utcTimezone }})</span>
            </dd>
          </div>
          <div class="flex flex-col gap-4">
            <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
              {{ $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_discount_rate_title') }}
            </dt>
            <dd>
              <span class="text-xl font-bold leading-xl text-on-surface-elevation-2">{{ discountDetails?.discountRate }}%</span>
            </dd>
          </div>
          <div class="flex flex-col gap-4">
            <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
              {{
                $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_title')
              }}
            </dt>
            <dd>
              <p class="text-sm font-regular leading-md text-on-surface-elevation-4">
                <safe-html
                  :html="
                    $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_msg')
                  "
                />
              </p>
              <div v-if="paidYn" class="mt-8">
                <st-table :columns="columns" :data="discountDetails?.discountCurrencies ?? []">
                  <template #row="{ row: item }">
                    <st-tr>
                      <st-td
                        class="px-16 py-[1rem] border-r-1 border-solid border-disabled-variant-3 first:!pl-16 last:!pr-16 last:border-r-0"
                      >
                        <store-display-price-info
                          :flagImage="flagImages[item.displayCurrencyCode]"
                          :currencyCode="item.displayCurrencyCode"
                          :originalPriceValue="item.originalDisplayPrice"
                          :displayPriceValue="item.displayPrice"
                        />
                      </st-td>
                      <st-td
                        class="px-16 py-[1rem] border-r-1 border-solid border-disabled-variant-3 first:!pl-16 last:!pr-16 last:border-r-0"
                      >
                        <payment-price-info
                          :currencyCode="item.salesCurrencyCode!"
                          :originalPriceValue="item.originalSalesPrice"
                          :paymentPriceValue="item.salesPrice"
                        />
                      </st-td>
                    </st-tr>
                  </template>
                </st-table>
              </div>
              <div v-else class="mt-8 text-md font-bold leading-lg text-on-surface-elevation-2">
                {{
                  $t(
                    'studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_free_msg'
                  )
                }}
              </div>
            </dd>
          </div>
          <div class="flex flex-col gap-4">
            <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
              {{ $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_limited_sales_title') }}
            </dt>
            <dd>
              <span class="text-xl font-bold leading-xl text-on-surface-elevation-2">{{
                discountDetails?.salesLimitYn === Confirmation.YES
                  ? $t(
                    'studio.prj_prod.this_prod.prod_mgmt_discount_register_limited_sales_enable_msg'
                  )
                  : $t(
                    'studio.prj_prod.this_prod.prod_mgmt_discount_register_limited_sales_disable_msg'
                  )
              }}</span>
            </dd>
          </div>
          <div
            v-if="discountDetails?.salesLimitYn === Confirmation.YES"
            class="flex flex-col gap-4"
          >
            <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
              {{
                $t(
                  'studio.prj_prod.this_prod.prod_mgmt_discount_register_limited_sales_quantity_title'
                )
              }}
            </dt>
            <dd class="flex gap-8 items-center">
              <span class="text-xl font-bold leading-xl text-on-surface-elevation-2">{{ formatNumberMultipleWithCommas(discountDetails?.salesLimitCount || 0) }}
                {{
                  $t(
                    'studio.prj_prod.this_prod.prod_mgmt_discount_register_limited_sales_quantity_unit_msg'
                  )
                }}</span>
              <span class="text-md font-medium leading-lg text-on-surface-elevation-3">{{
                t(
                  'studio.prj_prod.this_prod.prod_mgmt_discount_this_discount_details_limited_sales_remaining_quantity_msg',
                  {
                    remainingNum:
                      discountDetails.salesRemainCount === null ||
                      discountDetails.salesRemainCount === undefined
                        ? '-'
                        : formatNumberMultipleWithCommas(discountDetails.salesRemainCount)
                  }
                )
              }}</span>
            </dd>
          </div>
        </dl>
      </st-box>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { fetchCollectionDetailsApi } from '@/apis/collection.api';
import { fetchProductDiscountApi } from '@/apis/product-discount.api';
import { fetchProductSellingPriceApi } from '@/apis/product-selling-price.api';
import * as ImageAssets from '@/assets/images/selling-price';
import safeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTr from '@/components/common/table/st-tr.vue';
import PaymentPriceInfo from '@/components/selling-price/payment-price-info.vue';
import StoreDisplayPriceInfo from '@/components/selling-price/store-display-price-info.vue';
import { currencyCodes } from '@/constants/currency-codes.const';
import { DATE_TIME_FORMAT_WITH_DOT } from '@/constants/date-time.const';
import { Confirmation } from '@/enums/common.enum';
import { useCurrencyStore } from '@/stores/currency.store';
import type { CollectionInfos } from '@/types/collection/collection.type';
import type { CollectionDetailResponse } from '@/types/collection/collection-response.type';
import type { CurrencyInfoModel } from '@/types/currency/currency-common.type';
import type { ProductDiscountDetailResponse } from '@/types/product-discount/product-discount-response.type';
import type { Column } from '@/types/table.type';
import { formatNumberMultipleWithCommas } from '@/utils/currency.util';
import { formatDateTime, getUtcTimezone } from '@/utils/date.util';

const { t, locale } = useI18n();
const route = useRoute();
const { collectionId } = route.params;

const currencyStore = useCurrencyStore();
const { fetchCurrencyInfos } = currencyStore;

const collectionInfo = ref<CollectionDetailResponse>();
const flagImages = ref<Record<string, string>>(ImageAssets);
const discountDetails = ref<ProductDiscountDetailResponse>();
const exchangeRates = ref<CurrencyInfoModel[]>();
const paidYn = ref(false);
const isLoading = ref<boolean>(true);

const productName = computed(
  () =>
    collectionInfo.value?.productInfo.find(
      (c: CollectionInfos) => c.languageCd === locale.value
    )?.productName || ''
);

const columns: Column[] = [
  {
    title: t(
      'studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_displayed_price_header1'
    ),
    width: '50%'
  },
  {
    title: t(
      'studio.prj_prod.this_prod.prod_mgmt_discount_register_discounted_price_checkout_price_header2'
    ),
    width: 'auto'
  }
];

const utcTimezone = getUtcTimezone();

const fetchDiscountDetails = async () => {
  const result = await fetchProductDiscountApi(Number(collectionId), Number(route.params.mode));
  if (result) {
    discountDetails.value = result;
  }
};

const init = async () => {
  const result = await fetchCollectionDetailsApi(Number(collectionId));
  if (!result) {
    return;
  }
  collectionInfo.value = result;
  await fetchDiscountDetails();
  exchangeRates.value = await fetchCurrencyInfos(currencyCodes);
  const sellingPriceRes = await fetchProductSellingPriceApi(Number(collectionId));

  if (sellingPriceRes?.verifyPassedPrice) {
    paidYn.value = sellingPriceRes?.verifyPassedPrice?.paidYn === Confirmation.YES;
  }
  isLoading.value = false;
};

init();
</script>
